import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  HookComponentProvider,
  ContactForm,
  LocationSelect,
  Content,
  createDefaultMessageFromState,
  LocationsContext,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { useContext } from "react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"contact-container"}
          >
            <Grid.Column width={12}>
              <Header
                as={"h1"}
                className={"contact-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "contact_header",
                  defaultValue: "Contact",
                })}
              />
              <div className={"contact-form-container"}>
                <HookComponentProvider
                  hook={useContext}
                  component={
                    <ContactForm
                      buttonText={"Submit"}
                      centeredHeaders={false}
                      hideOptIn={false}
                      className={""}
                      fluidButton={true}
                      header={
                        <Grid className={"contact-form-header"}>
                          <Grid.Column width={8}>
                            <Header
                              as={"h3"}
                              content={getComponentContentNodeContent({
                                components:
                                  fishermanBusinessWebsitePage.components,
                                componentId: "contact_form_header",
                                defaultValue: "Contact Us",
                              })}
                            />
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <LocationSelect useContext={true} search={false} />
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            className={"form-description"}
                          >
                            <Content textAlign={"left"}>
                              <Content.Markup
                                width={16}
                                className={""}
                                isMarkdown={true}
                                content={getComponentContentNodeContent({
                                  components:
                                    fishermanBusinessWebsitePage.components,
                                  componentId: "contact_form_description",
                                })}
                              />
                            </Content>
                          </Grid.Column>
                        </Grid>
                      }
                      inline={false}
                      showLabels={true}
                      subheader={""}
                      termsCopy={""}
                      withContainer={false}
                      businessId={fishermanBusiness._id}
                      businessType={fishermanBusiness.type}
                      fields={[
                        {
                          label: "name",
                          placeholder: "Full Name",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "email",
                          placeholder: "Email",
                          type: "input",
                          inputType: "email",
                          required: true,
                        },
                        {
                          label: "phone",
                          placeholder: "Phone Number",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "tattooType",
                          placeholder: "Tattoo Type",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "message",
                          placeholder: "Message",
                          type: "textarea",
                          required: false,
                        },
                      ]}
                      constructMessage={createDefaultMessageFromState}
                      emailSubject={"My Contact Form"}
                    />
                  }
                  hookToPropsMap={{ locationId: "activeLocation.id" }}
                  hookParams={[LocationsContext]}
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
